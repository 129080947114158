import {
  Select,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { useState } from 'react';

import { canChangeStatus, getStatusChangeReason } from '@features/profile-overview';
import { type IProfile } from '@models/profileTypes';
import { PROFILE_STATUS_CODE, PROFILE_STATUS_DESCRIPTION } from '@models/profileTypes';

interface StatusSelectorProps {
  profile: IProfile;
  onChange: (newStatus: PROFILE_STATUS_CODE) => void;
}

const StatusSelector: React.FC<StatusSelectorProps> = ({ profile, onChange }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedStatus, setSelectedStatus] = useState<PROFILE_STATUS_CODE | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [disableReason, setDisableReason] = useState('');

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const newStatus = e.target.value as PROFILE_STATUS_CODE;
    const statusIsDisabled = !canChangeStatus(profile?.status as PROFILE_STATUS_CODE, newStatus, profile?.hasOpenItems);
    const reason = getStatusChangeReason(profile?.status as PROFILE_STATUS_CODE, newStatus, profile?.hasOpenItems);

    setSelectedStatus(newStatus);
    setIsDisabled(statusIsDisabled);
    setDisableReason(reason ?? '');
    onOpen();
  };

  const handleConfirmChange = (): void => {
    if (!isDisabled && selectedStatus != null) {
      onChange(selectedStatus);
    }
    onClose();
  };

  return (
    <>
      <Select
        size="sm"
        borderRadius="full"
        variant="solid"
        colorScheme="yellow"
        value={profile?.status}
        onChange={handleSelectChange}
        width={200}
      >
        {Object.values(PROFILE_STATUS_CODE).map((status) => (
          <option value={status} key={status}>
            {PROFILE_STATUS_DESCRIPTION[status].charAt(0).toUpperCase() +
              PROFILE_STATUS_DESCRIPTION[status].slice(1).toLowerCase()}
          </option>
        ))}
      </Select>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Status Change</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isDisabled ? (
              <Text color="red.500">{disableReason}</Text>
            ) : (
              <Text>
                Are you sure you want to change the status from{' '}
                <strong>{PROFILE_STATUS_DESCRIPTION[profile.status]}</strong> to{' '}
                <strong>{PROFILE_STATUS_DESCRIPTION[selectedStatus as PROFILE_STATUS_CODE]}</strong>?
              </Text>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" variant="outline" onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button color="white" bg="gray.800" onClick={handleConfirmChange} isDisabled={isDisabled}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default StatusSelector;
