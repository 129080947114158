interface IOption {
  label: string;
  value: any;
}

export const REGULATORY_INVESTIGATION_STATUS_OPTIONS: IOption[] = [
  { label: 'Under Investigation', value: 'UNDER_INVESTIGATION' },
  { label: 'No regulatory action', value: 'NO_REGULATORY_ACTION' }
];

export const LICENSING_REGISTRATION_STATUS_OPTIONS: IOption[] = [
  { label: 'In Progress', value: 'IN_PROGRESS' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'Not Registered', value: 'NOT_REGISTERED' }
];

export const AML_PROGRAM_REQUIRED_OPTIONS: IOption[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

export const AML_PROGRAM_ELEMENTS_OPTIONS: IOption[] = [
  { label: 'Designated', value: 'DESIGNATED' },
  { label: 'AML Officer', value: 'AML_OFFICER' },
  { label: 'Training', value: 'TRAINING' },
  { label: 'Customer Onboarding', value: 'CUSTOMER_ONBOARDING' },
  { label: 'Independent Testing', value: 'INDEPENDENT_TESTING' },
  { label: 'Internal Controls', value: 'INTERNAL_CONTROLS' }
];

export const SANCTIONS_PROGRAM_REQUIRED_OPTIONS: IOption[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

export const SANCTIONS_PROGRAM_ELEMENTS_OPTIONS: IOption[] = [
  { label: 'Senior Management Commitment', value: 'SENIOR_MANAGEMENT_COMMITMENT' },
  { label: 'Risk Assessment', value: 'RISK_ASSESSMENT' },
  { label: 'Policies and Procedures', value: 'POLICIES_PROCEDURES' },
  { label: 'Customer Due Diligence', value: 'CUSTOMER_DUE_DILIGENCE' },
  { label: 'Monitoring', value: 'MONITORING' },
  { label: 'Reporting', value: 'REPORTING' },
  { label: 'Training', value: 'TRAINING' },
  { label: 'Audit', value: 'AUDIT' },
  { label: 'Record Keeping', value: 'RECORD_KEEPING' }
];
