import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { Flex, Box, IconButton, Text, FormControl, FormLabel, Select } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useEffect, useRef, useState, createContext, useContext } from 'react';
import DatePicker from 'react-datepicker';

interface IEditableContext {
  value: string | number | Date;
  displayValue?: string | number | Date;
  type: string;
  options: Array<{ name: string; value: string | number }>;
}

interface IEditableFieldProps {
  label: string;
  value: string | number | undefined;
  type?: 'text' | 'select' | 'date';
  options?: Array<{ name: string; value: string | number }>;
  onConfirmChange: (value: string | number | Date) => void;
  isDisabled?: boolean;
  allowNull?: boolean;
}

const EditableContext = createContext<IEditableContext>({ value: '', type: 'text', options: [] });

const useEditableContext = (): IEditableContext => {
  const context = useContext(EditableContext);
  if (context === undefined) {
    throw new Error('useEditableContext must be used within an EditableProvider');
  }
  return context;
};

const Preview: React.FC = () => {
  const { displayValue, value } = useEditableContext();
  if (displayValue instanceof Date) {
    return <Text fontSize="sm">{format(displayValue, 'yyyy-MM-dd') ?? value}</Text>;
  }
  return <Text fontSize="sm">{(displayValue as string) ?? (value as string)}</Text>;
};

const EditableField: React.FC<IEditableFieldProps> = ({
  label,
  value = '',
  type = 'text',
  options = [],
  onConfirmChange,
  isDisabled = false,
  allowNull = true
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string | number | Date>(value);
  const initialDisplayValue = type === 'select' ? options.find((option) => option.value === value)?.name : value;

  const inputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);
  const datePickerRef = useRef<DatePicker>(null);
  useEffect(() => {
    if (isEditing) {
      (type === 'text' ? inputRef.current : selectRef.current)?.focus();
    }
  }, [isEditing, type]);

  useEffect(() => {
    if (isEditing && type === 'date') {
      datePickerRef?.current?.input?.focus();
      datePickerRef?.current?.setOpen(true);
    }
  }, [isEditing, type]);

  const handleEdit = (): void => {
    setIsEditing(true);
  };

  const handleCancel = (): void => {
    setIsEditing(false);
    setEditValue(value);
  };

  const handleConfirm = (): void => {
    setIsEditing(false);
    let valueToConfirm = editValue;
    if (type === 'date' && editValue instanceof Date) {
      valueToConfirm = editValue.toISOString().split('T')[0];
    }
    onConfirmChange(valueToConfirm);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setEditValue(e.target.value);
  };
  const displayValue = type === 'select' ? options.find((option) => option.value === editValue)?.name : editValue;

  const renderEditor = (): JSX.Element | null => {
    switch (type) {
      case 'text':
        return <input ref={inputRef} value={editValue as string} onChange={handleChange} style={{ flex: 1 }} />;
      case 'select':
        return (
          <Select
            ref={selectRef}
            value={editValue as string}
            onChange={handleChange}
            placeholder={allowNull ? 'Select an option' : undefined}
            style={{ flex: 1 }}
            size="sm"
            borderRadius="lg"
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </Select>
        );
      case 'date':
        return (
          <DatePicker
            ref={datePickerRef}
            selected={editValue != null && editValue !== '' ? new Date(editValue) : null}
            onChange={(date: Date | null) => {
              if (date == null) return;
              setEditValue(date);
            }}
            dateFormat="yyyy-MM-dd"
            customInput={<input style={{ flex: 1 }} />}
            closeOnScroll={false}
          />
        );
      default:
        return null;
    }
  };
  return (
    <FormControl display="flex" alignItems="center" my={0}>
      <FormLabel flexShrink={0} width="200px" fontSize="sm">
        {label}
      </FormLabel>
      <EditableContext.Provider
        value={{ value: editValue, displayValue: displayValue ?? initialDisplayValue, type, options }}
      >
        <Flex align="center" gap={2}>
          {isEditing ? (
            <>
              {renderEditor()}
              <IconButton size="sm" icon={<CheckIcon />} aria-label="confirm edit" onClick={handleConfirm} />
              <IconButton size="sm" icon={<CloseIcon />} aria-label="cancel edit" onClick={handleCancel} />
            </>
          ) : (
            <>
              <Box flex="1">
                <Preview />
              </Box>
              {!isDisabled && <IconButton size="sm" icon={<EditIcon />} aria-label="edit" onClick={handleEdit} />}
            </>
          )}
        </Flex>
      </EditableContext.Provider>
    </FormControl>
  );
};

export default EditableField;
