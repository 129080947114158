import { CloseIcon } from '@chakra-ui/icons';
import {
  Heading,
  Flex,
  Button,
  Tooltip,
  Grid,
  GridItem,
  IconButton,
  Text,
  Select,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { useState } from 'react';

import AttachmentModal from '@features/profile-wizard/components/common/AddAttachmentModal';
import {
  useDeleteDocumentMutation,
  useUpdateDocumentMutation,
  useGetProfileAttachmentsQuery,
  useGetProfileAttachmentTypesQuery
} from '@services/canaria.services';

import ConfirmationModal from './ConfirmationModal';

interface IAttachment {
  id: string;
  file: string;
  name: string;
  notes: string;
  status: string;
  attachmentType: string;
}

const AttachmentRow: React.FC<{
  attachment: IAttachment;
  attachmentTypes: Array<{ value: string; name: string }>;
  setSelectedAttachment: (attachment: { id: string; name: string }) => void;
  onDeleteOpen: () => void;
  updateDocument: any;
}> = ({ attachment, attachmentTypes, setSelectedAttachment, onDeleteOpen, updateDocument }) => {
  return (
    <Grid templateColumns="2fr 4fr 2.2fr 0.5fr" gap={2} alignItems="center" mb={2} minW="300px">
      <GridItem>
        <Text fontSize="sm" color="blue">
          <a href={attachment.file} style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">
            {attachment.name}
          </a>
        </Text>
      </GridItem>
      <GridItem>
        <Select
          size="xs"
          borderRadius="lg"
          value={attachment.attachmentType}
          onChange={async (e) => {
            await updateDocument({
              attachmentType: e.target.value
            });
          }}
          iconSize="15px"
          sx={{
            paddingRight: '24px'
          }}
        >
          {attachmentTypes?.map((type) => (
            <option key={type.value} value={type.value}>
              {type.name}
            </option>
          ))}
        </Select>
      </GridItem>
      <GridItem>
        <Select
          size="xs"
          borderRadius="lg"
          value={attachment.status}
          onChange={async (e) => {
            await updateDocument({
              status: e.target.value
            });
          }}
          iconSize="15px"
          sx={{
            paddingRight: '24px'
          }}
        >
          <option value="PENDING">Pending</option>
          <option value="APPROVED">Approved</option>
          <option value="REJECTED">Rejected</option>
        </Select>
      </GridItem>
      <GridItem>
        <Tooltip label="Delete attachment" aria-label="Delete attachment tooltip">
          <IconButton
            aria-label="Delete attachment"
            icon={<CloseIcon />}
            size="xs"
            colorScheme="gray"
            onClick={() => {
              setSelectedAttachment({ id: attachment.id, name: attachment.name });
              onDeleteOpen();
            }}
          />
        </Tooltip>
      </GridItem>
      {attachment.notes != null && (
        <GridItem colSpan={4}>
          <Text fontSize="xs" color="gray.600">
            {attachment.notes}
          </Text>
        </GridItem>
      )}
    </Grid>
  );
};

const Attachments: React.FC<{
  orgId: string;
  profileId: string;
}> = ({ orgId, profileId }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { data: attachments = [], refetch } = useGetProfileAttachmentsQuery({ orgId, profileId });
  const { data: attachmentTypes } = useGetProfileAttachmentTypesQuery(null);
  const [deleteDocument] = useDeleteDocumentMutation();
  const [updateDocument] = useUpdateDocumentMutation();

  const [selectedAttachment, setSelectedAttachment] = useState<{ id: string; name: string } | null>(null);

  const handleDelete = async (): Promise<void> => {
    if (selectedAttachment != null) {
      await deleteDocument({
        orgId,
        profileId,
        documentId: selectedAttachment.id
      });
      onDeleteClose();
    }
  };

  return (
    <>
      <Heading as="h1" size="md" textAlign="left" mb={1}>
        Attachments
      </Heading>
      <Flex direction="column">
        {attachments?.map((attachment, index) => (
          <AttachmentRow
            key={index}
            attachment={attachment}
            attachmentTypes={attachmentTypes}
            setSelectedAttachment={setSelectedAttachment}
            onDeleteOpen={onDeleteOpen}
            updateDocument={async ({ attachmentType, status }) => {
              try {
                await updateDocument({
                  orgId,
                  profileId,
                  documentId: attachment.id,
                  attachmentType,
                  status
                }).unwrap();
              } catch (error: any) {
                const errorMessage = error?.data?.status?.[0] ?? 'An error occurred';
                await refetch();
                toast({
                  title: 'Error',
                  description: errorMessage,
                  status: 'error',
                  duration: 5000,
                  isClosable: true
                });
              }
            }}
          />
        ))}
        {attachments?.length === 0 && <Text fontSize="sm">No attachments found</Text>}
        <Button size="sm" mt="4" maxW="60" color="white" background="gray.800" onClick={onOpen} alignSelf="flex-end">
          Add attachment
        </Button>
      </Flex>
      <AttachmentModal isOpen={isOpen} onClose={onClose} orgId={orgId} profileId={profileId} />
      {isDeleteOpen && (
        <ConfirmationModal
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
          onConfirm={handleDelete}
          title="Delete Attachment"
          body={`Are you sure you want to delete the attachment "${selectedAttachment?.name}"?`}
        />
      )}
    </>
  );
};

export default Attachments;
