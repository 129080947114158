import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  VStack,
  Button,
  Flex,
  Spinner,
  Center
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { useGetProfileAuditLogsQuery } from '@services/canaria.services';

// Attachment type with optional name
interface Attachment {
  id: number;
  name?: string; // Optional
}

// Screening with dynamic keys for new_hits
interface Screening {
  newHits: Record<string, number>; // Any string key representing the type of hit, with a number as its value
}

// Profiles type remains the same
interface Profiles {
  type: string; // "m2m" relationship type
  objects: string[]; // Array of string objects like "359 - donald trump"
  operation: string; // Example: "add" or "remove"
}

interface Inquiry {
  id: number;
  resolution: string;
  notes: string;
}

// Note type
interface Note {
  id: number;
  content: string;
}

// Add a new interface for the audit log entry
enum AuditLogAction {
  CREATE = 0,
  UPDATE = 1,
  DELETE = 2,
  ACCESS = 3
}

interface AuditLogEntry {
  id: number;
  timestamp: string;
  actor: {
    firstName: string;
    lastName: string;
  } | null;
  action: AuditLogAction;
  changes: Record<string, [string, string] | Attachment | Screening | Profiles | Inquiry | Note>;
  objectRepr: string;
  contentType: string;
}

// because they're logged manually or we already have them in the logentry
const KEYS_TO_IGNORE = ['lastRunScreening', 'updatedBy'];

const AuditLogModal: React.FC<{ isOpen: boolean; onClose: () => void; profileId: string }> = ({
  isOpen,
  onClose,
  profileId
}) => {
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 10;

  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('No active org ID found');
  }

  const { data, isLoading, refetch } = useGetProfileAuditLogsQuery(
    {
      orgId: activeOrgID,
      profileId,
      query: {
        page,
        page_size: PAGE_SIZE
      }
    },
    { skip: !isOpen }
  );

  useEffect(() => {
    if (isOpen) {
      void refetch();
    }
  }, [isOpen, refetch]);

  const auditLogs: AuditLogEntry[] = data?.results ?? [];
  const totalPages = Math.ceil((data?.count ?? 0) / PAGE_SIZE);

  const formatChanges = (log: AuditLogEntry): JSX.Element => {
    const changes = log.changes ?? [];
    return (
      <Box
        borderWidth="1px"
        borderRadius="lg"
        p={3}
        bg="gray.50"
        boxShadow="sm"
        _hover={{ boxShadow: 'md' }}
        transition="all 0.2s"
      >
        <VStack align="start" spacing={2}>
          {Object.entries(changes).map(([key, value]) => {
            if (key === 'profiles') {
              const profileChange = value as Profiles;
              const profileName = log.contentType === 'wallet' ? log.objectRepr : profileChange.objects[0];
              const action = profileChange.operation === 'add' ? 'added to' : 'removed from';
              return (
                <Text key={key} fontSize="sm">
                  <strong>Wallet:</strong> {profileName} was {action} profile
                </Text>
              );
            } else if (key === 'screening') {
              const screeningChange = value as Screening;
              return (
                <Box key={key} bg="blue.50" p={2} borderRadius="md" width="100%">
                  <Text fontSize="sm" fontWeight="bold" mb={1}>
                    Watchlist Ongoing Screening Complete
                  </Text>
                  {Object.entries(screeningChange.newHits).map(([matchType, count]) => (
                    <Text key={matchType} fontSize="sm" ml={2}>
                      • {count} New {matchType} Matches identified
                    </Text>
                  ))}
                </Box>
              );
            } else if (key === 'note' || key === 'attachment') {
              const item = value as Note | Attachment;
              const itemType = key === 'note' ? 'Note' : 'Attachment';
              const actionText =
                log.action === AuditLogAction.CREATE
                  ? 'added'
                  : log.action === AuditLogAction.UPDATE
                    ? 'updated'
                    : 'deleted';
              return (
                <Text key={key} fontSize="sm">
                  <strong>{itemType}:</strong> {actionText} -{' '}
                  {'name' in item ? item.name : 'content' in item ? item.content : `ID: ${item.id}`}
                </Text>
              );
            } else if (KEYS_TO_IGNORE.includes(key)) {
              return null;
            } else if (log.contentType === 'wallet' && key === 'lastInquiry') {
              return null;
            } else if (log.action === AuditLogAction.CREATE) {
              return null;
            }
            const entity = log.contentType === 'wallet' ? `Wallet ${log.objectRepr}` : 'Profile';
            if (typeof value === 'object' && 'type' in value && value.type === 'm2m') {
              const oldObjects = value.objects[0] ?? [];
              const newObjects = value.objects[1] ?? [];
              return (
                <Text key={key} fontSize="sm">
                  <strong>{key}</strong> in {entity} changed from{' '}
                  <Box as="span" bg="red.100" px={1} borderRadius="sm" mr={1}>
                    {Array.isArray(oldObjects) ? oldObjects.join(', ') : oldObjects}
                  </Box>{' '}
                  to{' '}
                  <Box as="span" bg="green.100" px={1} borderRadius="sm">
                    {Array.isArray(newObjects) ? newObjects.join(', ') : newObjects}
                  </Box>
                </Text>
              );
            }
            return (
              <Text key={key} fontSize="sm">
                <strong>{key}</strong> in {entity} changed {value[0] === '' ? 'to ' : 'from '}
                {value[0] !== '' && (
                  <Box as="span" bg="red.100" px={1} borderRadius="sm" mr={1}>
                    {value[0]}
                  </Box>
                )}
                {value[0] !== '' && 'to '}
                <Box as="span" bg="green.100" px={1} borderRadius="sm">
                  {value[1]}
                </Box>
              </Text>
            );
          })}
        </VStack>
      </Box>
    );
  };

  const filteredLogs = auditLogs.filter((log) => {
    const currentKeys = Object.keys(log.changes);
    const hasRelevantChanges = currentKeys.some((key) => {
      return ['profiles', 'screening', 'note', 'attachment'].includes(key);
    });

    const toSkip = currentKeys.every((key) => KEYS_TO_IGNORE.includes(key));

    return !(
      toSkip ||
      (!hasRelevantChanges && log.action === 0) ||
      (log.contentType === 'wallet' &&
        currentKeys.length === 2 &&
        currentKeys.includes('lastInquiry') &&
        currentKeys.includes('updatedBy'))
    );
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxWidth="90vw" maxHeight="90vh" display="flex" flexDirection="column">
        <ModalHeader>Profile Audit Log</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto" flex="1">
          {isLoading ? (
            <Center height="200px">
              <Spinner size="xl" color="blue.500" thickness="4px" />
            </Center>
          ) : filteredLogs.length === 0 ? (
            <Center height="200px">
              <Text fontSize="lg" fontWeight="medium" color="gray.500">
                {page === 1 ? 'No logs to show' : 'No logs to show on this page'}
              </Text>
            </Center>
          ) : (
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Date & Time</Th>
                  <Th>Username</Th>
                  <Th>Change Details</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredLogs.map((log) => (
                  <Tr key={log.id}>
                    <Td>{dayjs(log.timestamp).format('ddd, MMM D, YYYY [at] h:mm A')}</Td>
                    <Td>{log.actor !== null ? `${log.actor.firstName} ${log.actor.lastName}` : 'System'}</Td>
                    <Td>{formatChanges(log)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </ModalBody>
        {!isLoading && totalPages > 0 && (
          <Flex justifyContent="center" p={4}>
            <Button
              onClick={() => {
                setPage((prev) => Math.max(prev - 1, 1));
              }}
              isDisabled={page === 1}
              mr={2}
            >
              Previous
            </Button>
            <Text alignSelf="center" mx={2}>
              Page {page} of {totalPages}
            </Text>
            <Button
              onClick={() => {
                setPage((prev) => Math.min(prev + 1, totalPages));
              }}
              isDisabled={page === totalPages}
              ml={2}
            >
              Next
            </Button>
          </Flex>
        )}
      </ModalContent>
    </Modal>
  );
};

export default AuditLogModal;
