import { PROFILE_STATUS_CODE } from '@models/profileTypes';

export const getStatusChangeReason = (
  currentStatus: PROFILE_STATUS_CODE,
  targetStatus: PROFILE_STATUS_CODE,
  hasOpenItems: boolean
): string => {
  if (currentStatus === targetStatus) {
    return 'Already in this status';
  }
  if (currentStatus === PROFILE_STATUS_CODE.DRAFT && targetStatus === PROFILE_STATUS_CODE.COMPLETE && hasOpenItems) {
    return 'Cannot complete profile with unresolved hits';
  }
  if (currentStatus === PROFILE_STATUS_CODE.ARCHIVED && targetStatus === PROFILE_STATUS_CODE.COMPLETE && hasOpenItems) {
    return 'Cannot complete profile with unresolved hits';
  }
  if (currentStatus === PROFILE_STATUS_CODE.COMPLETE && targetStatus === PROFILE_STATUS_CODE.DRAFT) {
    return 'Cannot revert a completed profile to draft';
  }
  return '';
};

export const canChangeStatus = (
  currentStatus: PROFILE_STATUS_CODE,
  targetStatus: PROFILE_STATUS_CODE,
  hasOpenItems: boolean
): boolean => {
  if (currentStatus === targetStatus) {
    return false;
  }
  if (currentStatus === PROFILE_STATUS_CODE.DRAFT && targetStatus === PROFILE_STATUS_CODE.COMPLETE && hasOpenItems) {
    return false;
  }

  if (currentStatus === PROFILE_STATUS_CODE.ARCHIVED && targetStatus === PROFILE_STATUS_CODE.COMPLETE && hasOpenItems) {
    return false;
  }

  if (currentStatus === PROFILE_STATUS_CODE.COMPLETE && targetStatus === PROFILE_STATUS_CODE.DRAFT) {
    return false;
  }

  return true;
};
