import { InfoIcon } from '@chakra-ui/icons';
import { Box, Flex, Grid, GridItem, Text, Select, Heading, Tooltip as ChakraTooltip } from '@chakra-ui/react';
import { useState, useMemo } from 'react';

import MatchingEntity from '@features/panels/alertsPanel/components/MatchingEntity.component';
import AddressIdentificationSection from '@features/wallets/AddressIdentificationSection.component';
import ExposureData from '@features/wallets/ExposureData.component';
import NameDisplay from '@features/wallets/NameDisplay.component';
import NotesDisplay from '@features/wallets/NotesDisplay.component';
import OverviewSection from '@features/wallets/OverviewSection.component';
import ScreeningInfoDisplay from '@features/wallets/ScreeningInfoDisplay.component';
import TriggersSection from '@features/wallets/TriggersSection.component';
import { getExposureInformation } from '@features/wallets/Wallet.component';
import { getTriggerDifferences } from '@utils/alerts';
import { RISK_BG_COLORS, RISK_BORDER_COLORS } from '@utils/consts';

const AlertBody = ({ alert, onResolveMatch, activeEntityId, setActiveEntityId }): JSX.Element => {
  const [filter, setFilter] = useState<'all' | 'unresolved' | 'resolved'>('all');

  const { sum, exposureRisk, severeVal, highVal, mediumVal, lowVal } = getExposureInformation(
    alert.current_inquiry_result?.result?.exposures ?? []
  );

  const chartData = {
    labels: ['Severe', 'High', 'Medium', 'Low'],
    datasets: [
      {
        label: '% of Total Exposure',
        data: [severeVal, highVal, mediumVal, lowVal],
        backgroundColor: [RISK_BG_COLORS.SEVERE, RISK_BG_COLORS.HIGH, RISK_BG_COLORS.MEDIUM, RISK_BG_COLORS.LOW],
        borderColor: [
          RISK_BORDER_COLORS.SEVERE,
          RISK_BORDER_COLORS.HIGH,
          RISK_BORDER_COLORS.MEDIUM,
          RISK_BORDER_COLORS.LOW
        ],
        borderWidth: 0
      }
    ]
  };

  const filteredEntities = useMemo(() => {
    return (alert.profile_inquiries ?? []).filter((inquiry) => {
      if (filter === 'all') return true;
      if (filter === 'unresolved') return inquiry.resolution === 'NO_RESOLUTION';
      if (filter === 'resolved') return inquiry.resolution !== 'NO_RESOLUTION';
      return true;
    });
  }, [alert.profile_inquiries, filter]);

  const ProfileDetail = ({ label, value }): JSX.Element | null => {
    if (value == null) return null;

    return (
      <GridItem>
        <Flex direction="row" gap={2}>
          <Text>{label}:</Text>
          <Text fontWeight={600}>{value}</Text>
        </Flex>
      </GridItem>
    );
  };

  const AlertProfileDetails = ({ profile }): JSX.Element => {
    return (
      <>
        <ProfileDetail label="Entity Type" value={profile?.entity_type} />
        <ProfileDetail label="Full Name" value={profile?.full_legal_name} />
        <ProfileDetail label="Date of Birth" value={profile?.date_of_birth} />
        <ProfileDetail label="DBA Name" value={profile?.dba_trade_name} />
        <ProfileDetail label="Citizenship" value={profile?.citizenship?.english_name} />
        <ProfileDetail label="Country of Establishment" value={profile?.place_of_establishment?.english_name} />
        <ProfileDetail label="Place of Birth" value={profile?.place_of_birth?.english_name} />
        <ProfileDetail label="ID Type" value={profile?.id_type?.name} />
        <ProfileDetail label="ID Number" value={profile?.id_number} />
        <ProfileDetail label="ID Issuer" value={profile?.id_issuer?.english_name} />
        <ProfileDetail label="Unique Identification Number" value={profile?.unique_identification_number} />
      </>
    );
  };
  return (
    <>
      {alert.resourcetype === 'WatchlistAlert' ? (
        <Box p={5} bg="white" outline="1px solid black" mb={4}>
          <Text fontWeight={800}>Profile Information</Text>
          <Grid
            templateColumns="auto auto"
            pt={6}
            columnGap={5}
            flex="col"
            outline="1px solid black"
            bg="rgba(190, 217, 3, 0.2)"
            padding={5}
          >
            <AlertProfileDetails profile={alert.profile} />
          </Grid>
          <Flex justifyContent="space-between" alignItems="center" mt={5} mb={2}>
            <Text fontWeight={800}>Matching Entities</Text>
            <Select
              width="200px"
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value as any);
              }}
            >
              <option value="all">All Hits</option>
              <option value="unresolved">Unresolved Hits</option>
              <option value="resolved">Resolved Hits</option>
            </Select>
          </Flex>
          <Box height="400px" overflowY="auto">
            {filteredEntities.length > 0 ? (
              filteredEntities.map((inquiry) => (
                <MatchingEntity
                  key={inquiry.id}
                  entity={inquiry}
                  onResolve={onResolveMatch}
                  isExpanded={activeEntityId === inquiry.id}
                  onToggle={() => {
                    setActiveEntityId(activeEntityId === inquiry.id ? null : inquiry.id);
                  }}
                />
              ))
            ) : (
              <Flex height="100%" alignItems="center" justifyContent="center">
                <Box textAlign="center" color="gray.500">
                  <Text>
                    No matches found
                    {filter !== 'all' ? ' for the current filter' : ''}.
                  </Text>
                  {filter !== 'all' && (
                    <Text mt={2}>
                      Try changing the filter to see {filter === 'unresolved' ? 'resolved' : 'unresolved'} matches.
                    </Text>
                  )}
                </Box>
              </Flex>
            )}
          </Box>
        </Box>
      ) : (
        <Box p={5} bg="white" outline="1px solid black" mb={4} overflowY="auto" maxHeight="600px">
          <Text fontWeight={800} mb={4}>
            Wallet Details:{' '}
          </Text>
          <Flex flexDirection="row" pt={2} flexWrap="wrap" gap={3}>
            <Box flexGrow="1" flexBasis={180}>
              <NameDisplay
                name={alert.wallet?.name}
                address={alert.wallet?.address}
                walletId={alert.wallet?.id}
                orgId={alert.org_id}
              />
              <ScreeningInfoDisplay
                createdAt={alert.wallet?.created_at}
                lastInquiryCreatedAt={alert.wallet?.last_inquiry?.created_at}
                frequency={alert.wallet?.schedule?.frequency}
                walletId={alert.wallet?.id}
                orgId={alert.org_id}
                walletName={alert.wallet?.name}
                walletAddress={alert.wallet?.address}
              />
            </Box>
            <Box flexGrow="2">
              <OverviewSection
                address={alert.wallet?.address}
                risk={alert?.current_inquiry_result?.result?.risk}
                cluster={alert?.current_inquiry_result?.result?.cluster}
              />
            </Box>
          </Flex>
          <Box outline="1px solid black" mt={4} pl={5} pr={5} pt={4} pb={4} mb={4} bg="white">
            <Heading size="sm">
              Exposure
              <ChakraTooltip label="Exposure refers to the relationship between the screened address and other entities that is created by transfers to/from the address. Exposure is determined by the last identified source of funds and the first identified destination of funds for all transfers that have been received to, or sent from, the screened address.">
                <InfoIcon />
              </ChakraTooltip>
            </Heading>
            <ExposureData
              exposures={[...(alert.current_inquiry_result?.result?.exposures ?? [])]}
              sum={sum}
              exposureRisk={exposureRisk}
              chartData={chartData}
            />
          </Box>
          <NotesDisplay notes={alert.wallet?.notes} walletId={alert.wallet?.id} orgId={alert.org_id} />
          <AddressIdentificationSection
            addressIdentifications={alert?.current_inquiry_result?.result?.addressIdentifications}
          />
          <TriggersSection
            triggers={
              getTriggerDifferences(
                alert?.prev_inquiry_result?.result?.triggers,
                alert?.current_inquiry_result?.result?.triggers
              ).changedTriggers
            }
          />
        </Box>
      )}
    </>
  );
};

export default AlertBody;
