import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, Textarea, Button, VStack, Radio, RadioGroup, Stack, useToast, Grid } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

import type { Inquiry } from '@features/panels/alertsPanel/types';
import { formatDate } from '@features/panels/alertsPanel/utils/alertUtils';

// @ts-expect-error I'm not entirely sure why an error is being thrown here
const MotionBox = motion(Box);
const LineField = ({ label, value }: { label: string; value: string | undefined | null }): JSX.Element | null => {
  if (value === null || value === undefined) {
    return null;
  }
  return (
    <Box flex="1 0 21%">
      <Flex direction="column" justify="flex-start" height="100%">
        <Text fontSize="xs" color="gray.500" mb={1}>
          {label}
        </Text>
        <Text
          borderBottom="1px solid"
          borderColor="gray.300"
          pb={1}
          minHeight="3em"
          whiteSpace="normal"
          wordBreak="break-word"
        >
          {value}
        </Text>
      </Flex>
    </Box>
  );
};

const ListField = ({ label, items }: { label: string; items: Array<string | undefined> }): JSX.Element | null => {
  return (
    <VStack align="stretch" spacing={0} borderLeft="1px solid" borderColor="gray.300" pl={2} flex={1}>
      <Text fontSize="xs" color="gray.500">
        {label}
      </Text>
      {items.map(
        (item, index) =>
          item !== undefined && (
            <Text key={index} borderBottom="1px solid" borderColor="gray.300" pb={1}>
              {item}
            </Text>
          )
      )}
    </VStack>
  );
};

const MatchingEntity = ({
  entity,
  onResolve,
  isExpanded,
  onToggle
}: {
  entity: Inquiry;
  onResolve: (id: number, resolution: string, notes: string) => void;
  isExpanded: boolean;
  onToggle: () => void;
}): JSX.Element => {
  const toast = useToast();
  const [resolution, setResolution] = useState<'TRUE_MATCH' | 'FALSE_POSITIVE' | 'NO_RESOLUTION'>(
    entity.resolution as 'TRUE_MATCH' | 'FALSE_POSITIVE' | 'NO_RESOLUTION'
  );
  const [notes, setNotes] = useState(entity.notes);

  const isResolved = entity.resolution !== 'NO_RESOLUTION';

  const getButtonText = (): string => {
    if (isExpanded) {
      if (isResolved) {
        return resolution === 'TRUE_MATCH' ? 'True Match' : 'False Positive';
      }
      return 'Open Hit';
    } else {
      if (isResolved) {
        return resolution === 'TRUE_MATCH' ? 'True Match' : 'False Positive';
      }
      return 'Open Hit';
    }
  };

  const getButtonIcon = (): JSX.Element => {
    return isExpanded ? <ViewOffIcon /> : <ViewIcon />;
  };

  return (
    <Box borderWidth="1px" borderColor="black" borderStyle="solid" borderRadius="0" p={4} mb={4}>
      <Flex justifyContent="space-between" alignItems="center" gap={4}>
        <Text fontSize="sm" width="25%">
          Entity Name: {entity.result_name ?? 'N/A'}
        </Text>
        <Text fontSize="sm" width="25%">
          Source List: {entity.result?.list_entry?.list_source?.name ?? 'N/A'}
        </Text>
        <Text fontSize="sm" width="20%">
          Category: {entity.result?.list_entry?.list_source?.category?.name ?? 'N/A'}
        </Text>
        <Text fontSize="sm" width="15%">
          Score: {entity.result?.score != null ? `${(entity.result.score * 100).toFixed(2)}%` : 'N/A'}
        </Text>
        <Button
          onClick={onToggle}
          bg={isResolved ? (resolution === 'TRUE_MATCH' ? 'red.500' : '#BED902') : 'gray.200'}
          color={isResolved && resolution === 'TRUE_MATCH' ? 'white' : 'black'}
          _hover={{ bg: isResolved ? (resolution === 'TRUE_MATCH' ? 'red.600' : 'green.500') : 'gray.300' }}
          size="sm"
          borderWidth="1px"
          whiteSpace="normal"
          overflowWrap="break-word"
          textAlign="center"
          height="auto"
          minHeight="31px"
          py={0}
          width="200px"
        >
          <Flex alignItems="center" justifyContent="space-around" width="100%" padding="0 15px 0 15px">
            {getButtonText()}
            {getButtonIcon()}
          </Flex>
        </Button>
      </Flex>
      <AnimatePresence>
        {isExpanded && (
          <MotionBox
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            overflow="hidden"
          >
            <Flex mt={4}>
              <VStack align="stretch" flex={1}>
                <Grid templateColumns="repeat(5, 1fr)" gap={4} width="100%">
                  <LineField label="First Name" value={entity.result?.list_entry?.name?.first_name} />
                  <LineField label="Last Name" value={entity.result?.list_entry?.name?.last_name} />
                  <LineField label="Occupation" value={entity.result?.list_entry?.catch_all?.Occupation?.[0]} />
                  <LineField label="Entity Type" value={entity.result?.list_entry?.type?.name} />
                  <LineField
                    label="List Names"
                    value={entity.result?.list_entry?.list_source?.lists?.map((list) => list.name).join('; ')}
                  />
                </Grid>
                <Flex gap={4} width="100%" flexWrap="wrap">
                  <ListField
                    label="Aliases"
                    items={
                      entity.result?.list_entry?.aliases
                        ?.map((alias) => {
                          if (alias.full_name === undefined) {
                            if (alias.first_name !== undefined && alias.last_name !== undefined) {
                              return `${alias.first_name} ${alias.last_name}`;
                            }
                          }
                          return alias.full_name;
                        })
                        .filter(Boolean) ?? []
                    }
                  />
                  <ListField
                    label="Citizenships"
                    items={
                      entity.result?.list_entry?.citizenships
                        ?.map((citizenship) => {
                          if (citizenship.official_name === undefined) {
                            return citizenship.name;
                          }
                          return citizenship.official_name;
                        })
                        .filter(Boolean) ?? []
                    }
                  />
                  <ListField
                    label="Addresses"
                    items={
                      entity.result?.list_entry?.addresses?.map((address) => address.address).filter(Boolean) ?? []
                    }
                  />
                  <ListField
                    label="Dates of Birth"
                    items={
                      entity.result?.list_entry?.dobs
                        ?.map((dob) => {
                          if (dob.raw !== undefined) return dob.raw;
                          if (dob.start !== undefined && dob.end !== undefined) {
                            const startFormatted = formatDate(dob.start);
                            const endFormatted = formatDate(dob.end);
                            return startFormatted === endFormatted
                              ? startFormatted
                              : `${startFormatted} - ${endFormatted}`;
                          }
                          return undefined;
                        })
                        .filter(Boolean) ?? []
                    }
                  />
                </Flex>
                <Flex gap={4} width="100%" flexWrap="wrap">
                  {Object.entries(entity.result?.list_entry?.catch_all ?? {})
                    .filter(([key]) => key !== 'Occupation') // Skip 'Occupation' as it's already rendered
                    .map(([key, value]: [string, any]) => (
                      <LineField
                        key={key}
                        label={key}
                        value={
                          Array.isArray(value)
                            ? value?.join('; ')
                            : value !== null && value !== undefined
                              ? String(value)
                              : null
                        }
                      />
                    ))}
                </Flex>
              </VStack>
              <VStack align="stretch" width="200px" ml={4}>
                <Text fontSize="xs" color="gray.500">
                  Resolution
                </Text>
                <RadioGroup
                  onChange={(value) => {
                    setResolution(value as 'TRUE_MATCH' | 'FALSE_POSITIVE');
                  }}
                  value={resolution}
                  isDisabled={isResolved}
                >
                  <Stack>
                    <Radio value="TRUE_MATCH">True Match</Radio>
                    <Radio value="FALSE_POSITIVE">False Positive</Radio>
                  </Stack>
                </RadioGroup>
                <Text fontSize="xs" color="gray.500" mt={2}>
                  Notes
                </Text>
                <Textarea
                  value={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                  placeholder="Enter notes here"
                  size="sm"
                  isDisabled={isResolved}
                />
                <Button
                  mt={2}
                  bg="black"
                  color="white"
                  _hover={{ bg: 'gray.800' }}
                  onClick={() => {
                    if (resolution === 'NO_RESOLUTION' || notes.trim() === '') {
                      toast({
                        status: 'error',
                        title: 'Error',
                        description: 'Please select a resolution and add notes to resolve the match!',
                        isClosable: true
                      });
                      return;
                    }
                    onResolve(entity.id, resolution, notes);
                  }}
                  isDisabled={isResolved || resolution === 'NO_RESOLUTION' || notes.trim() === ''}
                >
                  Resolve Match
                </Button>
                {isResolved && (
                  <Text fontSize="sm" color="gray.500" mt={2}>
                    This match has been resolved
                  </Text>
                )}
              </VStack>
            </Flex>
          </MotionBox>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default MatchingEntity;
